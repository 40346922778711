header {
  overflow-x: hidden;
}

@mixin imgClassName($imgUrl, $position) {
  background-image: url($imgUrl);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: $position;
  z-index: -10;
}

.hero_BG_IMG {
  @include imgClassName("../../public/assets/hero-section-bg.jpg", absolute);
}
.online_appointment{
  @include imgClassName("../../public/assets/Appointment/online-appointment_BG.jpg", absolute);
}
.physical-appointment{
  @include imgClassName("../../public/assets/Appointment/physical-appointment_BG.webp", absolute);
}
.electroconvulsive-therapy-ect{
  @include imgClassName("../../public/assets/subServices/ect.webp", absolute);
}
.letterBoxImg {
    @include imgClassName("../../public/assets/letterBox.webp", "static");
    height: 6rem;
    width: 6rem;
}

.psychological-services {
  @include imgClassName(
    "../../public/assets/subServices/psychological-services.png",
    absolute
  );
}
.psychiatric-services {
  @include imgClassName(
    "../../public/assets/subServices/psychiatric-services.jpg",
    absolute
  );
}
.speech-language-therapy {
  @include imgClassName(
    "../../public/assets/subServices/speech-tharapy.jpg",
    absolute
  );
}
.career-counseling {
  @include imgClassName(
    "../../public/assets/subServices/career-counseling.jpg",
    absolute
  );
}
.rehab-services {
  @include imgClassName(
    "../../public/assets/subServices/treatment.jpg",
    absolute
  );
}
.marital-counseling {
  @include imgClassName(
    "../../public/assets/subServices/marital.jpg",
    absolute
  );
}
.treatment-psychiatric-illnesses {
  @include imgClassName(
    "../../public/assets/subServices/addiction.jpg",
    absolute
  );
}

.heroContent {
  width: 100vw;
  z-index: 1 !important;
  background-color: #0e0e22ab;
}

.about_hero_bg {
  @include imgClassName("../../public/assets/header-img.jpg", absolute);
}

.header-img-contact {
  @include imgClassName("../../public/assets/header-img-contact.jpg", absolute);
}
.for_contact {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1 !important;
}

.about_Hero_Content {
  z-index: 1 !important;
}

.contact_Hero_bg {
  @include imgClassName("../../public/assets/Contact_US_Hero.webp", absolute);
}

.footer-bg {
  @include imgClassName("../../public/assets/footer-bg.jpg", static);
}

.footer-wrapper {
  background-color: rgba(8, 145, 178, 0.5);
  z-index: 2 !important;
  width: 100vw;
  height: fit-content;
}

.team_bg {
  @include imgClassName("../../public/assets/Team-imgs/team-bg.jpg", absolute);
}

// Blog Section
/* Variables */
$primary-color: #2563eb; /* Tailwind's blue-500 equivalent */
$secondary-color: #eff6ff; /* Tailwind's blue-50 equivalent */
$text-color: #1f2937; /* Tailwind's gray-800 equivalent */
$background-color: #f9fafb; /* Tailwind's gray-50 equivalent */
$highlight-color: #eab308; /* Tailwind's amber-500 for emphasis */

/* Global Styling */
body {
  background-color: $background-color;
  font-family: "Inter", sans-serif;
  color: $text-color;
  line-height: 1.6;
}

/* Blog Container */
.blog-container {
  max-width: 70rem;
  margin: 0 auto;
  padding: 3rem 1.5rem;

  h1.blog-title {
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
    color: $text-color;
    margin-bottom: 2rem;

    // text-decoration: underline;
    // text-decoration-color: $primary-color;
    // text-decoration-thickness: 2px;
  }

  .blog-content {
    font-size: 1.125rem;
    line-height: 1.8;
    color: darken($text-color, 10%);
    margin-bottom: 2rem;

    h2,
    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      color: #fc8602;
      // margin-bottom: 1.5rem;
    }

    p,
    li {
      margin-bottom: 1rem;
    }

    ul {
      margin-left: 1.5rem;
      list-style: disc;

      li {
        line-height: 1.6;
      }
    }

    strong {
      color: $primary-color;
    }

    em {
      color: $highlight-color;
      font-style: italic;
    }

    a {
      color: $primary-color;
      text-decoration: underline;
      &:hover {
        color: darken($primary-color, 15%);
      }
    }
  }

  /* Service Section */
  .blog-service {
    background-color: $secondary-color;
    border-left: 4px solid $primary-color;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      color: $primary-color;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.125rem;
      color: $text-color;
      line-height: 1.8;
    }
  }
}

/* Not Found Message */
.blog-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: bold;
  color: red;
  text-align: center;
  padding: 2rem;
}

/* Call-to-Action Button */
.cta-button {
  display: block;
  width: fit-content;
  margin: 2rem auto 0;
  padding: 0.75rem 1.5rem;
  background-color: $primary-color;
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 0.5rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: darken($primary-color, 10%);
    transform: scale(1.05);
  }

  &:focus {
    outline: 3px solid $highlight-color;
  }
}
