@tailwind base;
@tailwind components;
@tailwind utilities;

button, a, img, input, textarea , select{
    user-select: none;
}


#root{
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}