.loader {
    position: relative;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    .circle {

        // padding: 11px;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 10px solid transparent;
      animation: spin 1.5s linear infinite;
    }
  
    .circle:nth-child(1) {
      border-top-color: #1e3a8a;
      animation-delay: 0s;
    }
  
    .circle:nth-child(2) {
      border-top-color: #3b82f6;
      animation-delay: 0.2s;
    }
  
    .circle:nth-child(3) {
      border-top-color: #60a5fa;
      animation-delay: 0.4s;
    }
  
    .circle:nth-child(4) {
      border-top-color: #93c5fd;
      animation-delay: 0.6s;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  